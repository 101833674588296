import { createClient } from "@supabase/supabase-js";

//export type searchLog = Database['public']['Tables']['search_log']['Row'][];
export const supabaseClient = createClient(
  process.env.NEXT_PUBLIC_SUPABASE_URL,
  process.env.NEXT_PUBLIC_SUPABASE_ANON_KEY,
);

export const fetchRecord = async (tableName, recordId) => {
  try {
    const { data, error } = await supabaseClient
      .from(tableName)
      .select()
      .match({ id: recordId });
    return data;
  } catch (error) {
    console.log(`Error fetching data in table ${tableName}`, error);
    throw error;
  }
};

/* updates record based on matching table id */
// ex: recordIdArr = ['store_id', recordId]
// ex: recordIdArr = ['search_id', recordId]
export const updateRecord = async (tableName, columnData, recordIdArr) => {
  try {
    const { data, error } = await supabaseClient
      .from(tableName)
      .update(columnData)
      .eq(recordIdArr[0], recordIdArr[1])
      .select();

    if (error) {
      console.log(`Error updating data in table ${tableName}:`);
      throw new Error(error.message);
    }
    return data;
  } catch (error) {
    console.log(`Error updating data in table ${tableName}:`, error);
  }
};

//inserts data and returns record
export const insertData = async (tableName, columnData, printErrors = true) => {
  try {
    const { data: insertedData, error } = await supabaseClient
      .from(tableName)
      .insert(columnData)
      .select();

    if ((error != null) & (printErrors == true)) {
      console.log(`Error inserting data in table ${tableName}:`, error);
    }
    return insertedData;
  } catch (error) {
    console.log(`Error inserting data in table ${tableName}:`, error);
    throw error;
  }
};

/*
This function allows you to bulk upsert categories
It checks for uniqueness based on retailer_category_id and retailer match 
*/
export async function bulkUpsertCategories(categories) {
  const { data, error } = await supabaseClient
    .from("category")
    .upsert(categories, { onConflict: ["retailer_category_id", "retailer"] }) //no need to fix this error
    .select();

  if (error) {
    console.error("Error performing bulk upsert:", error.message);
    return;
  }

  return data; // Array of inserted/updated rows
}

/*
This function allows you to bulk upsert stores
It checks for uniqueness based on retailer_store_id and retailer match 
*/
export async function bulkUpsertStores(categories) {
  const { data, error } = await supabaseClient
    .from("store_info")
    .upsert(categories, { onConflict: ["retailer_store_id", "retailer"] }) //no need to fix this error
    .select();

  if (error) {
    console.error("Error performing bulk upsert:", error.message);
    return;
  }

  return data; // Array of inserted/updated rows
}

/*
This function allows you to bulk upsert stores
It checks for uniqueness based on retailer_category_id and retailer match 
*/
export async function bulkUpsert(tableName, tableData, onConflict = []) {
  const { data, error } = await supabaseClient
    .from(tableName)
    .upsert(tableData, { onConflict: onConflict }) //no need to fix this error
    .select();

  if (error) {
    console.error(
      `Error performing bulk upsert into ${tableName}: `,
      error.message,
    );
    return;
  }

  return data; // Array of inserted/updated rows
}

/* upserts data into supabase */
export const upsertData = async (
  tableName,
  columnData,
  optionalConstraint = "",
  optionalUpdateColumns = ["nearby_zipcode"],
  optionalConflictData = {},
) => {
  try {
    const { data: upsertedData, error } = await supabaseClient
      .from(tableName)
      .upsert(columnData, {
        onConflict: {
          action: "update",
          constraint: optionalConstraint,
          update_columns: optionalUpdateColumns,
          where: optionalConflictData,
        },
      })
      .select();

    if (error) {
      console.log(`Error upserting data in table ${tableName}`, error);
      throw new Error(error.message);
    }

    return upsertedData;
  } catch (error) {
    console.log(`Error upserting data in table ${tableName}`, error);
    throw error;
  }
};
