import Image from "next/image";
import magnifyingGlass from "@/img/magnifying_glass_black.svg";
import { FC } from "react";
import LocationInput from "../location/LocationInput";
import TrendingProducts from "./TrendingSlider";
import PlacesAutocomplete from "../googleMaps/AutocompleteInput";
import { GoogleGeocodeLocation } from "utils/googleMapsUtils";

interface SearchPaneProps {
  zipcode: string;
  setZipcode: (zipcode: string) => void;
  searchQuery: string;
  setSearchQuery: (searchQuery: string) => void;
  handleLocationInputClick: () => void;
  handleSubmit: (e, modalZipcode?) => void;
  searchQueryError: string;
  zipcodeError: string;
  isLoaded: boolean;
}

const retailers = [
  { name: "Target", img: "/img/target.png" },
  { name: "Kroger", img: "/img/kroger.png" },
  { name: "Best Buy", img: "/img/bestbuy.png" },
  { name: "Walmart", img: "/img/walmart.png" },
  { name: "Amazon", img: "/img/amazon.png" },
];

const NewSearchPane: FC<SearchPaneProps> = (props) => {
  return (
    <section>
      <div className="bg-white p-6 md:p-8">
        <div className="container mx-auto grid max-w-[1200px] grid-cols-1 bg-white text-black">
          <div className="pb-8 md:pb-12">
            <div className="justify-items-center md:grid md:grid-cols-2 md:gap-6">
              <LeftSide {...props} />
              <RightSide
                setSearchQuery={props.setSearchQuery}
                handleLocationInputClick={props.handleLocationInputClick}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const LeftSide: FC<SearchPaneProps> = ({
  zipcode,
  setZipcode,
  searchQuery,
  setSearchQuery,
  handleLocationInputClick,
  handleSubmit,
  searchQueryError,
  zipcodeError,
  isLoaded,
}) => {
  return (
    <div className="my-auto">
      <h1 className="mb-2 pb-4 text-4xl font-bold tracking-wider text-black md:text-5xl">
        <p>FIND IN-STOCK</p>
        <p>PRODUCTS</p>
        <p>NEAR YOU</p>
      </h1>

      <div className="md:mx-0">
        <form>
          <div className="mb-2">
            <i className="absolute p-3 pl-4">
              <Image src={magnifyingGlass} alt={""} />
            </i>
            <input
              className="hover:outline-slate-600 h-12 !w-full rounded-md border border-gray-400 bg-transparent pl-12 placeholder:text-gray-701 focus:border-0 focus:border-transparent focus:outline-blue"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              type="text"
              name="query"
              placeholder="Search products"
              // minLength={3}
              pattern=".{3,}"
              onInvalid={(e) =>
                e.currentTarget.setCustomValidity(
                  "Your search query must be at least 3 characters long.",
                )
              }
              required
            />
          </div>
          <div className="mb-6">
            {isLoaded && (
              <PlacesAutocomplete setZipcode={setZipcode} zipcode={zipcode} />
            )}
          </div>
          <p className="text-sm text-red">{searchQueryError}</p>
          <p className="text-sm text-red">{zipcodeError}</p>
          <button
            onClick={handleSubmit}
            className="h-12 w-full rounded-3xl bg-red py-3.5 text-center font-bold !text-white"
            type="submit"
            // onSubmit={handleSubmit}
          >
            Search
          </button>
        </form>
      </div>
      {/* Retailers */}
      <div className="Retailers-Container -ml-4 grid h-24 grid-cols-5 justify-items-center gap-4 pt-6 md:pt-12">
        {retailers.map((retailer) => (
          <Image
            key={retailer.name}
            src={retailer.img}
            alt={retailer.name}
            width={0}
            height={0}
            className="max-w-28 max-h-24 w-16 object-contain"
            sizes="100vh"
          />
        ))}
      </div>
    </div>
  );
};

const RightSide = ({ setSearchQuery, handleLocationInputClick }) => {
  return (
    <div className="flex flex-col justify-center pt-12 md:pt-6">
      {/* Button */}
      <div className="mx-auto grid-cols-1 justify-center md:flex md:flex-col">
        {/* <div className="flex justify-center pb-4">
          <button className="h-12 w-9/12 rounded border border-red-500 bg-white text-center font-bold text-black">
            <Link href="/watchlist">GET IN-STOCK ALERTS</Link>
          </button>
        </div> */}
        {/* Image */}
        <div className="Image-Container grid h-[25rem] w-[22rem] content-center justify-center rounded-full border bg-[#e5f0f8] xxs:m-[-2rem] xs:w-[25rem] sm:h-[28rem] sm:w-[28rem] md:h-[30rem] md:w-[30rem]">
          <h2 className="mx-auto pt-20 text-3xl font-semibold text-black md:pt-24 md:text-4xl">
            Trending
          </h2>
          <TrendingProducts
            setSearchQuery={setSearchQuery}
            handleLocationInputClick={handleLocationInputClick}
          />
        </div>
      </div>
    </div>
  );
};

export default NewSearchPane;
