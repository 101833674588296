import airpods from "@/img/airpods.png";
import useMobileDetect from "hooks/useMobileDetect";
import Image from "next/image";
import { FC } from "react";
import Link from "next/link";

const CantFind = () => {
  const { isMobile } = useMobileDetect(); // On mobile, Title and subtitle are combined.
  return (
    <div className="mx-auto bg-white px-6 text-black md:p-12">
      {!isMobile && (
        <>
          <p className=" text-center text-3xl font-bold">
            {`CAN'T FIND YOUR PRODUCT?`}
          </p>
          <p className="pt-2 text-center text-2xl font-bold">{`Ask friends and neighbors at Youdle Nation`}</p>
        </>
      )}
      <div className="justify-items-center px-2 py-12 md:flex md:px-0 md:pt-16">
        <div className="flex w-full flex-col items-center justify-between md:flex-row xl:px-40">
          <TopContent isMobile={isMobile} />
          <ProductImage />
          {/* <BottomContent /> */}
        </div>
      </div>
    </div>
  );
};

const ProductImage = () => {
  return (
    <div className=" pt-2  md:right-36 md:float-right md:my-[-15px]  xl:right-64">
      <div className="Image-Container mx-auto grid h-[300px] w-[300px] min-w-[300px] content-center justify-center rounded-full border bg-[#DAEAF5] md:mx-auto md:h-[320px] md:w-[320px] md:min-w-[320px]">
        <Image src={airpods} className="h-auto w-56" alt="Video game image" />
      </div>
    </div>
  );
};

const TopContent: FC<{ isMobile: boolean }> = ({ isMobile }) => {
  return (
    <div className="md:w-3/4 md:py-28 lg:pl-32">
      <div className="md:w-9/12">
        <p className="self-center text-center text-2xl font-bold md:text-start">
          {isMobile &&
            `Can’t find your product? Ask friends and neighbors at Youdle Nation`}
        </p>
        <p className="self-center py-4 text-center md:self-start md:text-start">
          {`Youdle Nation is a local community that will`}
          <br></br>
          {`help you find what you're looking for.`}
        </p>
        <div className="flex justify-center md:justify-start">
          <Link href="https://www.facebook.com/groups/getyoudle">
            <button className="h-12 w-fit rounded-3xl border border-red-500 bg-white px-6 text-center text-lg font-bold text-black">
              ASK YOUDLE NATION
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

const BottomContent = () => {
  return (
    <div className="md:w-3/4">
      <div className="md:w-9/12">
        <p className="self-center pt-12 text-center text-2xl font-bold md:self-start md:text-start">
          Sign up for in-stock alerts, sent directly to via email or SMS!
        </p>
        <div className="flex justify-center pt-4 md:justify-start">
          <button className="w-100 h-12 rounded-3xl border border-red-500 bg-white px-5 text-center text-lg font-bold text-black">
            <Link href="/watchlist">Get In-Stock Alerts</Link>
          </button>
        </div>
      </div>
    </div>
  );
};

export default CantFind;
