import Parser from 'rss-parser';
import { bulkUpsert, supabaseClient } from './supabaseUtils.mjs';
import { Feed, NewsItem } from './types/news';

const parser = new Parser();

async function parseRss(newsItems: NewsItem[]) {
    const res: Feed[] = [];
    for (const item of newsItems) {
        try {
            const feed = (await parser.parseURL(item.URL)) as Feed;
            res.push(feed);
        }
        catch (error) {
            throw new Error(error);
        }

    }
    return res;
}

export async function updateNews(newsItems: NewsItem[]) {
    try {
        const feeds = await parseRss(newsItems);
        const dbFeeds = feeds.map((feed, idx) => {
            return {
                feedId: newsItems[idx].ID,
                feedUrl: newsItems[idx].URL,
                feedTitle: feed.title,
                feedDescription: feed.description,
                feedLink: feed.link,
                title: feed.items[0].title,
                link: feed.items[0].link,
                content: feed.items[0].content,
                author: feed.items[0].creator,
                pubDate: feed.items[0].pubDate,
                updated_at: new Date().toISOString()
            }
        });
        const data = await bulkUpsert('rss_news', dbFeeds, ['feedId']);
        console.log(data);
    }
    catch (error) {
        console.log(error);
    }

}

export async function parseRssFromUrl(feedUrl: string) {
    console.log('-------------->', feedUrl);
    const newsFeed = await parser.parseURL(feedUrl);
    console.log(newsFeed);
}



export async function getNewsHeaders() {
    try {
        const { data, error } = await supabaseClient.from('rss_news').select('feedUrl, title');
        return data;
    }
    catch (e) {
        console.log(e);
    }
}