"use client";

import { FC, Fragment, useEffect, useState } from "react";
import { Transition } from "@headlessui/react";
import ReactDOM from "react-dom";

const delay = (ms) => new Promise((res) => setTimeout(res, ms));

interface MailchimpModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

const MailchimpModalTransition: FC<MailchimpModalProps> = ({
  open,
  setOpen,
}) => {
  const [email, setEmail] = useState("");
  const [signedUp, setSignedUp] = useState(false);

  const signUp = (email: string) => {
    fetch(`api/mailchimp?email=${email}`);
    setSignedUp(true);
    delay(2000).then(() => setOpen(false));
    localStorage.setItem("dont-show", "true");
  };

  const dontShowAgain = (checked: boolean) => {
    if (checked) {
      localStorage.setItem("dont-show", "true");
    } else {
      localStorage.removeItem("dont-show");
    }
  };

  return (
    <Transition.Root show={open} as={"div"}>
      {/* <div className="relative z-10"> */}
      <div className="fixed bottom-0 z-10 sm:right-4">
        <div className="flex min-h-full items-center justify-center text-center sm:items-center">
          <Transition.Child
            as={Fragment}
            enter="transition-all ease-in-out duration-1000"
            enterFrom="opacity-100 translate-y-full"
            enterTo="opacity-100 translate-y-0"
            leave="transition-all ease-in-out duration-300"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-100 translate-y-full"
          >
            <div className="relative transform rounded-lg text-left transition-all">
              <div className="z-50 max-w-lg rounded-t-2xl border border-b-0 border-gray-600 bg-white p-6 text-black">
                <div
                  className={`absolute inset-0 h-full w-full rounded-t-2xl border-gray-600 bg-white bg-opacity-70 transition-opacity duration-700 ease-in ${
                    signedUp ? `z-20 opacity-100` : ` -z-10 opacity-0`
                  }`}
                >
                  <div className="flex h-full items-center justify-center">
                    <p className="text-3xl font-bold text-black">Thank you!</p>
                  </div>
                </div>
                <div className="flex justify-between gap-8">
                  <p className="pb-4 font-bold sm:text-lg ">
                    SUBSCRIBE TO THE YOUDLE NEWSLETTER
                  </p>
                  <button
                    className="z-50 h-6 w-6"
                    onClick={() => setOpen(false)}
                  >
                    <svg
                      className="h-6 w-6"
                      width="32"
                      height="32"
                      viewBox="0 0 32 32"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M24 24L16 16M16 16L8 8M16 16L24 8M16 16L8 24"
                        stroke="black"
                        stroke-width="1"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </button>
                </div>
                <div className="pr-4">
                  <p className="text-xs sm:text-base">
                    Subscribe to the Youdle Newsletter and Get News, Shortages,
                    Recalls and Real-Time, In-Demand Groceries Near You.
                  </p>
                </div>
                <div className="flex flex-col gap-2 pt-4">
                  <input
                    className="hover:outline-slate-600 h-12 !w-full rounded-md border border-gray-400 bg-transparent pl-2 placeholder:text-gray-701 focus:border-0 focus:border-transparent focus:outline-blue"
                    type="text"
                    name="query"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <button
                    className="h-12 w-full rounded-3xl bg-red py-3.5 text-center font-bold !text-white"
                    type="submit"
                    onClick={() => signUp(email)}
                  >
                    Sign Up
                  </button>
                  <div className="flex items-center gap-2">
                    <input
                      type="checkbox"
                      name="dont-show-again"
                      id="dont-show-again"
                      className="h-4 w-4 border-red accent-red
                       "
                      onChange={(e) => dontShowAgain(e.target.checked)}
                    />
                    <p>{`Don't show this again`}</p>
                  </div>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </div>
      {/* </div> */}
    </Transition.Root>
  );
};

const MailchimpModal: FC<MailchimpModalProps> = (props) => {
  const [mounted, setMounted] = useState(false);

  useEffect(() => setMounted(true), []);
  return mounted
    ? ReactDOM.createPortal(
        <MailchimpModalTransition {...props} />,
        document.body,
      )
    : null;
};

export default MailchimpModal;
