import { FC } from "react";

export const LoadingTrendingCard: FC = () => {
  return (
    <div className="relative flex h-[21rem] flex-col justify-center md:h-96">
      <div className="xxs:h-38 xxs:w-38 mx-auto flex h-36 w-44 animate-pulse justify-center rounded-2xl sm:h-44 sm:w-52">
        <svg
          className="h-full max-h-60 w-auto justify-self-center text-gray-200 dark:text-gray-600"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
          viewBox="0 0 20 18"
        >
          <path d="M18 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm4.376 10.481A1 1 0 0 1 16 15H4a1 1 0 0 1-.895-1.447l3.5-7A1 1 0 0 1 7.468 6a.965.965 0 0 1 .9.5l2.775 4.757 1.546-1.887a1 1 0 0 1 1.618.1l2.541 4a1 1 0 0 1 .028 1.011Z" />
        </svg>
      </div>
      <div className="h-16 animate-pulse px-6 pt-6 text-sm font-bold md:text-base">
        <div className="mb-4 h-2 w-full rounded-full bg-gray-200 dark:bg-gray-600" />
        <div className="h-2 w-full rounded-full bg-gray-200 dark:bg-gray-600" />
      </div>
      <div className="flex justify-center py-6 pb-6">
        <button
          className="h-10 w-10/12 rounded border border-red-500 text-center font-semibold text-red-500 sm:h-12"
          type="submit"
        >
          Search
        </button>
      </div>
    </div>
  );
};

export const LoadingTrendingProducts = [
  <LoadingTrendingCard key={1} />,
  <LoadingTrendingCard key={2} />,
  <LoadingTrendingCard key={3} />,
  <LoadingTrendingCard key={4} />,
  <LoadingTrendingCard key={5} />,
];
