import { get } from "http";
import Link from "next/link";
import { FC, useEffect, useState } from "react";
import Marquee from "react-fast-marquee";
import { getNewsHeaders } from "utils/rssUtils";

const YoudleNews: FC = () => {
  const [rssInfo, setRssInfo] = useState<{ feedUrl: string; title: string }[]>(
    [],
  );

  /* Randomize array in-place using Durstenfeld shuffle algorithm
  https://stackoverflow.com/a/12646864 */
  function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      const temp = array[i];
      array[i] = array[j];
      array[j] = temp;
    }
  }

  useEffect(() => {
    async function fetchFeedTitles() {
      const data = await getNewsHeaders();
      console.log("--->", data);
      shuffleArray(data);
      setRssInfo(data);
    }
    fetchFeedTitles();
  }, []);

  return (
    <header className="bg-youdleLightGray grid h-16 align-middle text-lg text-black">
      <div className="flex items-center overflow-x-hidden">
        {/* You MUST have w-screen. Marquee breaks with autoFill={true} if not. */}
        <div className="w-screen whitespace-nowrap">
          <Marquee autoFill={true} pauseOnHover={true}>
            {rssInfo.length !== 0 ? (
              rssInfo.map((newsItem, idx) => (
                <span key={idx} className="mx-4 underline">
                  <Link
                    href={`/news?url=${newsItem.feedUrl}`}
                    dangerouslySetInnerHTML={{ __html: newsItem.title }}
                  >
                    {/* {newsItem.title} */}
                  </Link>
                </span>
              ))
            ) : (
              <LoadingNewsHeader />
            )}
          </Marquee>
        </div>
      </div>
    </header>
  );
};

const LoadingNewsHeader = () => {
  return (
    <div className="max-w-sm animate-pulse px-4">
      <span className="mb-2.5 h-2.5 max-w-[300px] rounded-full bg-gray-200 text-transparent dark:bg-gray-400">
        {/* This is a really hacky way for this to work, but it does! */}
        -------------------------------
      </span>
    </div>
  );
};

export default YoudleNews;
