import { useEffect, useState } from "react";

const NewReviews = () => {
  const reviews = [
    {
      id: 1,
      text: "The sightings [Youdle] help me locate products that my brother needs for my mom.",
      author: "Angie Myles",
    },
    {
      id: 2,
      text: "Product Sightings [Youdle] has been a big help looking for items around my area without wasting time and has driving around.",
      author: "MsBabeghirl Haari",
    },
    {
      id: 3,
      text: "I really enjoy Youdle. I have recommended it to my family and friends.",
      author: "Tilly Green, Hayes Valley",
    },
  ];
  const [randReview, setRandReview] = useState(null);

  useEffect(() => {
    setRandReview(reviews[Math.floor(Math.random() * reviews.length)]);
  }, []);

  if (!randReview) {
    return null; // This shouldn't ever happen, but just in case.
  }
  return (
    <div className="bg-blue-600 px-6">
      <div className=" px-1 py-8 text-white sm:px-32">
        <p className="text-center text-lg font-medium italic">
          {randReview.text}
        </p>
        <p className="w-11/12 pt-4 text-end">- {randReview.author}</p>
      </div>
    </div>
  );
};

export default NewReviews;
