/* eslint-disable no-undef */
import { Libraries } from "@react-google-maps/api";

export const libraries: Libraries = ["geometry", "drawing", "places"];

export type Map = google.maps.Map;
export type LatLng = google.maps.LatLng;
export type Marker = google.maps.Marker;
export type Position = {
  lat: number;
  lng: number;
};
export type Place = google.maps.places.AutocompletePrediction;

export type GoogleGeocodeLocation = google.maps.GeocoderResult;
